body {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300; }

h1 {
  margin: 2.5rem auto 2.5rem auto;
  text-align: center;
  font-weight: 300; }

h2 {
  margin: 2.2rem auto 2.2rem auto;
  text-align: center;
  font-weight: 300; }

h3 {
  margin: 1.9rem auto 1.9rem auto;
  text-align: center;
  font-weight: 300; }

h4 {
  margin: 1.6rem auto 1.6rem auto;
  text-align: center;
  font-weight: 300; }

h5 {
  margin: 1.3rem auto 1.3rem auto;
  text-align: center;
  font-weight: 300; }

h6 {
  margin: 1rem auto 1rem auto;
  text-align: center;
  font-weight: 300; }

h1 {
  font-weight: 600;
  margin-top: 5rem; }

:focus:not(.focus-visible) {
  outline: none; }

html {
  font-size: 16px; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    html {
      font-size: 18px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 20px; } }

a {
  color: #0366d6;
  cursor: pointer; }

code {
  color: #e01a76; }

p {
  text-align: justify; }

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac; }

.container {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.modal-title {
  margin: 0; }
